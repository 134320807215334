

<template>
  <transition name="fade">
  <div class="settings">
   <main-nav />
    <main-titlebar myclass="yellowBar" showback="true" icon="fas fa-address-card" />

      <div v-for="p in profile" :key="p.id" >

        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              Naam
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.fullname}}
          </div>
        </div>
 
        <div class="form-group">
           <div class="trip-view-col">
            {{p.address}}
          </div>
        </div>


        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              Telefoon
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.phone}}
          </div>
        </div>
        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              Email
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.email}}
          </div>
        </div>
       <div class="form-group">
          <div class="col-2-title textAlignCenter">
              P nummer
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.pnr}}
          </div>
        </div>        
        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              KvK nummer
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.kvk}}
          </div>
        </div>
        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              BTW nummer
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.btw}}
          </div>
        </div>
        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              BSN nummer
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.bsn}}
          </div>
        </div>
        <div class="form-group">
          <div class="col-2-title textAlignCenter">
              Chauffeurskaart
          </div>
          <div class="col-2 textAlignLeft ">
            {{p.dcard}}
          </div>
        </div>      

        <div class="trip-view-row">
          <div class="trip-view-col">
            <router-link to="/my/profile/edit">
              <div class="default-button"><i class="fa-solid fa-pen-to-square"></i> Bewerken</div>
            </router-link>
          </div>
        </div>


      </div>


  </div>
  </transition>
</template>
<script>
import UserService from "../../services/user-service";

export default {
  name:'view-profile',
  data() {
    var status = 'loading'
    var profile;
    return { profile, status}
  },
  mounted() {
    var targ = this;
    UserService.getMyProfile().then(
      (response) => {
        targ.status = response.status;
        targ.profile = response.data;
      },
      (error) => {
          error.toString();
      }
    );
  },
  computed: {

  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>
