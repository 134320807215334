import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_TRIPTRADER_API_HOST;
class UserService {
  getMyProfile() {
    return axios.get(API_URL + 'my/profile',{ headers: authHeader() }).then(response => {
      console.log("getMyProfile response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
      }
      return response.data;
    });
  }


  saveMyProfile(user) {
    return axios
    .post(API_URL + '/my/profile/save', user,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }


  testMail() {
    return axios
    .post(API_URL + '/admin/testmail', {},{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }

}
export default new UserService();